import React from 'react'
import PropTypes from 'prop-types'
import { Link, graphql } from 'gatsby'

import Layout from '../components/Layout'
import BlogRoll from '../components/BlogRoll'

import Certyfikat from '../../static/img/certyfikatTUV.webp'


import "../components/layout.css"

export const JakoscPageTemplate = ({ intro }) => (
         <div>
           <div>
             <div></div>
    </div>
    <section className="section section--gradient antyPadding">
             <div className="container">
        <div className="section AboutP antyPadding">
                 <div className="columns">
                   <div className="column is-10 is-offset-1 StoPro">
                     <div className="content">
                       <div className="columns">
                  <div className="column is-12">
                  </div>
                       </div>
                <div className=""><h3 className="has-text-weight-semibold is-size-2 headingCSS5">
                  {intro.heading}<hr className="hr"></hr>
                </h3>
                  <div className="grid-containerJakosc">
                    <div className="JakoscOpis">Ekochron dąży do tego, by jakość nie była jedynie etykietą lecz wartością potwierdzaną przez wszystkich klientów.
Dowodami wysokiej jakości produktów Ekochron są:</div>
                    <div className="jo1 joUniversall"><p className="Naglowek">1. Własne laboratorium</p><p className="opisJakosc">Firma dysponuje własnym laboratorium, w którym testuje wyroby pod kątem wytrzymałości oraz odporności na czynniki zewnętrzne.</p></div>
                    <div className="jo5 joUniversall"><p className="Naglowek">5. Praca ludzi z wiedzą i doświadczeniem</p><p className="opisJakosc">Nad jakością naszych wyrobów pracuje kadra wyspecjalizowanych pracowników, których wiedza oraz doświadczenie pozwalają na rozwój i sukces firmy.</p></div>
                    <div className="jo2 joUniversall"><p className="Naglowek">2. Zgodność z normami</p><p className="opisJakosc">Wszystkie wyroby są testowane także w zewnętrznych jednostkach laboratoryjnych na zgodność z normami EN-PN 516 (komunikacja dachowa) oraz EN-PN 517 (haki bezpieczeństwa).</p></div>
                    <div className="jo6 joUniversall"><p className="Naglowek">6. Współpraca z ITB i TZUS</p><p className="opisJakosc">Firma Ekochron współpracuje z Instytutem Techniki Budowlanej (ITB) w Warszawie, a od 2004 roku także z ostrawskim oddziałem czeskiego instytutu budownictwa Technický a zkušební ústav stavební Praha (TZUS) w Pradze.
To właśnie w 2004 roku, jako pierwsi w Polsce wdrożyliśmy wysokiej jakości dachowe haki zabezpieczające przed upadkiem z wysokości.
Od tego czasu wszystkie nasze wyroby objęte zharmonizowanymi normami UE wdrażamy przy współpracy z TZUS, gdyż jest ona gwarantem poprawności konstrukcji i wysokiej jakości produktów.</p></div>
                    <div className="jo3 joUniversall"><p className="Naglowek">3. Wdrożenie ISO-9001</p><p className="opisJakosc">W 2000 roku nasza firma wdrożyła System Zarządzania Jakością ISO-9001 stale nadzorowany przez TUV NORD. Działania zgodne z systemem ISO umocniły pozycję firmy na rynku krajowym i pozwoliły na systematyczne i ciągłe podnoszenie jakości wyrobów, otwierając tym samym drzwi na rynek europejski.</p></div>
                    <div className="jo7 joUniversall"><p className="Naglowek">7. Zaufanie w Polsce i krajach Europy</p><p className="opisJakosc">Wysoka jakość oferowanych produktów spowodowała, że produkty marki Ekochron dostępne są również w wielu krajach Europy.</p></div>
                    <div className="jo4 joUniversall"><p className="Naglowek">4. Własna lakiernia proszkowa</p><p className="opisJakosc">Inwestycje w infrastrukturę i park maszynowy zaowocowały powstaniem własnej lakierni proszkowej oraz umożliwiły dalszy postęp automatyzacji procesów, co przełożyło się w bezpośredni sposób na wzrost wydajności i szybkość reakcji na potrzeby klienta.</p></div>
                    <div className="jo8 joUniversall"><p className="Naglowek">8. Nowe kierunki rozwoju</p><p className="opisJakosc">Współpracując ściśle ze swoimi klientami oraz obserwując potrzeby rynku, wdrażamy nowoczesne produkty, wyznaczając nowe kierunki rozwoju, stając się w branży producentów osprzętu dachowego liderem i wzorem dla konkurencji.</p></div>
                    <div className="JakoscOpis2">Cieszy nas systematyczny wzrost ilości firm podejmujących stałą współpracę z naszym przedsiębiorstwem.
                    Obecnie, nasza firma dostarcza swoje produkty do blisko 500 polskich klientów. Posiadamy również odbiorców zagranicznych.
Dziękujemy tym, którzy są z nami od wielu lat i zapraszamy wszystkich, którzy się jeszcze zastanawiają.</div>
                  </div>



                       </div>
                <div className="imgALL divCertyfikat"><img alt=" " className="imgCertyfikatJakosci"  src={Certyfikat}></img></div>
                       <div className="columns artykulyProd">
                         <div className="column is-12 has-text-centered">

                         </div>
                       </div>
                       <div className="column is-12 wpisy">
                         <h3 className="has-text-weight-semibold is-size-2">
                           Najnowsze wpisy:
                         </h3>
                         <BlogRoll />
                         <div className="column is-12 has-text-centered">
                           <Link className="btn" to="/blog">
                             Czytaj więcej
                           </Link>
                         </div>
                       </div>
                     </div>
                   </div>
                 </div>
               </div>
             </div>
           </section>
         </div>
       );

JakoscPageTemplate.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  heading: PropTypes.string,
  subheading: PropTypes.string,
  description: PropTypes.string,
  intro: PropTypes.shape({
    blurbs: PropTypes.array,
  }),
  gallery: PropTypes.shape({
    blurbs: PropTypes.array,
  }),
}

const JakoscPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark

  return (
    <Layout>
      <JakoscPageTemplate
        image={frontmatter.image}
        heading={frontmatter.heading}
        subheading={frontmatter.subheading}
        description={frontmatter.description}
        intro={frontmatter.intro}
        gallery={frontmatter.gallery}
      />
    </Layout>
  )
}

JakoscPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default JakoscPage

export const pageQuery = graphql`
         query JakoscPageTemplate {
           markdownRemark(
             frontmatter: { templateKey: { eq: "jakosc-page" } }
           ) {
             frontmatter {
               heading
               description
               subheading
               intro {
                 blurbs {
                   image {
                     childImageSharp {
                       fluid(maxWidth: 780, quality: 100) {
                         ...GatsbyImageSharpFluid
                       }
                     }
                   }
                   text
                 }

                 heading
                 description
               }
               gallery {
                 blurbs {
                   image {
                     childImageSharp {
                       fluid(maxWidth: 780, quality: 100) {
                         ...GatsbyImageSharpFluid
                       }
                     }
                   }

                 }

               }
             }
           }
         }
       `;
